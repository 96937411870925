body {
  font-family: sans-serif;
}

.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.show-inline {
  display: inline-block !important;
}

.loader-wrapper,
.spinner {
  height: 100px;
  margin: 20px 0;

  img {
    max-height: 100%;
    min-height: 70px;
  }
}

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #ffffff;
  font-size: 11px;
  text-indent: -9999em;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.alert {
  margin-bottom: 20px;
}

.message-container {
  padding: 20px;
  background-color: #fff;
  color: #404040;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
}

.button-warning {
  background: red;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

#flow-subscribe,
#flow-confirm {
  margin: 0 auto;
}

#flow-wifi {
  text-align: center;
}

#flow-spinner.show {
  display: flex !important;
  justify-content: center;
}

#subscribe-frame,
#identify-frame {
  display: none;
  border: 0;
}

.setup-error {
  background-color: #dc3545;
  color: white;
  font-weight: bold;
  padding: 1em;
}

%d-none {
  display: none !important;
}
%d-inline {
  display: inline !important;
}
%d-block {
  display: block !important;
}

// https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@mixin state-display($state) {
  $state: str-replace($state, " ", "-");
  .d-#{$state}-none {
    @extend %d-none;
  }
  .d-#{$state}-inline {
    @extend %d-inline;
  }
  .d-#{$state}-block {
    @extend %d-block;
  }
}

$states: init, subscribe, username, lander, confirm, pin, wifi, error, success, iframe,
  "otp challenge", "otp validate", "otp validate error", "he otp challenge", "he otp validate", subscribing, redirecting, identifying,
  timeout, spinner, "spinner-subscribe", continue, "pre redirect";

@each $state in $states {
  body[data-state="#{$state}"] {
    @include state-display($state);
  }
}

// CSS Patterns used on multiple pages
//CSS Pulse Start
button.PMCpulse {
  animation-name: PMCpulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

button.PMCpulse:hover {
  animation-name: none;
}

@keyframes PMCpulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}
//CSS Pulse End

//CSS Blink start
.PMCblinker {
  animation: PMCblinky 1s linear infinite;
}

@keyframes PMCblinky {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
//CSS Blink End

//CSS Nudge Start

.PMCnudge {
  animation: PMCnudge 1s infinite;
  animation-timing-function: ease;
  -webkit-animation: PMCnudge 1s infinite;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-timing-function: ease;
  /* Safari 4.0 - 8.0 */
}

@keyframes PMCnudge {
  from {
    left: 0px;
  }
  to {
    left: 10px;
  }
}

// Safari 4.0 - 8.0
@-webkit-keyframes PMCnudge {
  from {
    left: 0px;
  }
  to {
    left: 10px;
  }
}
//CSS Nudge End

//CSS Utility classes start
.PMCshow {
  display: block;
}
.PMChide {
  display: none;
}
.PMCtext {
  display: block;
  width: 90%;
  margin: 0 auto 1em auto;
  font-size: 1.05em;
}
.PMCtext.PMCstrong {
  font-weight: 500;
  font-size: 1.25em;
  margin: 0.5em auto 0.5em auto;
}

//CSS Utility classes end
